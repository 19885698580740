<template>
    <el-dialog
    v-model="dialogVisible"
    :close-on-click-modal="false"
    title="文物详情"
    width="70%"
    >
    <div class="loTwo">
        <div class="loTbox">
            <div class="nameBox">
                基本信息
                <div class="nameImg"></div>
            </div>
            <div class="basicBox" v-if="relicMsg.relicUnit">
                <div class="basicRight">
                    <div class="BR-imgBox">
                        <img :src="`${imgUrl}?appendixId=${relicMsg.relicUnit.relicImage}`"/>
                    </div>
                </div>
                <div class="basicLeft">
                    <div class="BL-lineBox">
                        <div>
                            <div>编号：{{ relicMsg.relicUnit.relicCode }}</div>
                            <div>等级：{{ relicMsg.relicUnit.relicLevel }}</div>
                        </div>
                        <div>
                            <div>名称：{{ relicMsg.relicUnit.relicName }}</div>
                            <div>材质：{{ relicMsg.relicUnit.relicTexture }}</div>
                        </div>
                        <div>
                            <div>年代：{{ relicMsg.relicUnit.relicYears }}</div>
                            <div>文物状态：{{ relicMsg.relicUnit.relicState==1?'在馆':'外出' }}</div>
                        </div>
                    </div>
                    <div class="BR-imgMsg">
                        <img v-if="relicMsg.codeImg" :src="relicMsg.codeImg"/>
                    </div>
                </div>
            </div>
            <div class="nameBox">
                病害状况
                <div class="nameImg"></div>
            </div>
            <div class="storageBox">
                <div class="quesBox" v-if="relicMsg.relicDiseaseList">
                    <div class="quesTitle">腐蚀</div>
                    <div class="ques1" v-for="(item, index) in relicMsg.relicDiseaseList" :key="index">
                        <div class="ques1Img">
                            <el-carousel arrow="never" height="100%" indicator-position="none">
                            <el-carousel-item v-for="i in item.bodyImage" :key="i">
                                <img class="carouselImg" :src="`${imgUrl}?appendixId=${i}`"/>
                            </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="ques1Text">
                        <div class="ques1Time">{{ item.updateDate }}</div>
                        <div>
                            {{ item.diseaseResult }}
                        </div>
                        <div class="ques1File">
                            <span @click="loadFiles(item.reportFile[0])">检测报告</span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="nameBox">
                保存现状
                <div class="nameImg"></div>
            </div>
            <div class="warningBox">
                时间：2023.10.16-至今 保存位置：xx-xx-xx <br/>
                <div class="labelBox">环境因素</div>
                <div class="factsBox">
                    <div class="factItem">
                        <div class="quesBox2">
                            <div class="quesTitle2">温热因素</div>
                            <div class="ques1" >
                                温度：恒温20℃<br/>
                                湿度：无
                            </div>
                        </div>
                    </div>
                    <div class="factItem">
                        <div class="quesBox2">
                            <div class="quesTitle2">光照因素</div>
                            <div class="ques1 ques2" >
                                光照：无<br/>
                                紫外含量：无
                            </div>
                        </div>
                    </div>
                </div>
                <div class="factsBox">
                    <div class="quesBox2">
                        <div class="quesTitle2">污染物因素</div>
                        <div class="disCss" >
                            <div class="factItem">
                                <h4>污染物综合评估</h4>
                                有机污染物综合评估：<br/>
                                无机污染物综合评估：<br/>
                                含硫污染物综合评估
                            </div>
                            <div class="factItem">
                                <h4>特征污染物评估</h4>
                                甲酸：<br/>
                                乙酸：<br/>
                                臭氧：<br/>
                                甲醛：<br/>
                                二氧化碳：<br/>
                                二氧化硫：<br/>
                                热氧化氮：<br/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="factsBox">
                    <div class="quesBox2">
                        <div class="quesTitle2">生物因素</div>
                        <div>
                            文物有害动物：无<br/>
                            <h4>空气微生物</h4>
                            <div class="disCss">
                                <div>真菌总数：</div>
                                <div>环境RLU值：</div>
                                <div>优势菌种：</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="factsBox">
                    <div class="quesBox2">
                        <div class="quesTitle2">室外因素</div>
                        <div>
                           <div> 气象信息：</div>
                            空气质量：
                        </div>
                    </div>
                </div>
                <div class="labelBox">预防措施</div>
                <div class="factsBox">
                    <div class="factItem">
                        <div class="quesBox2">
                            <div class="quesTitle2">监测措施</div>
                            <div class="ques1" >
                                环境监测：恒温20℃<br/>
                                本体监察：无
                            </div>
                        </div>
                    </div>
                    <div class="factItem">
                        <div class="quesBox2">
                            <div class="quesTitle2">调控措施</div>
                            <div class="ques1 ques2" >
                                光照：无<br/>
                                紫外含量：无
                            </div>
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确定</el-button>
      </span>
    </template>
    </el-dialog>
</template>
<script>
import {site_relicDetail, site_relicImg} from '@/api/site-home.js'
export default{
    data(){
        return {
            dialogVisible:false,
            relicMsg:{},
            imgUrl:window.g.imgUrl,
            smallDataList:[
                {name:'温度', value:28, unit:'℃'},
                {name:'湿度', value:58, unit:'%RH'},
                {name:'光照', value:28, unit:'Lx'},
                {name:'紫外', value:42, unit:'uv/cm3'},
                {name:'二氧化碳', value:203, unit:'ppm'},
            ],
            miniDataList:[
                {name:'温度', value:26, unit:'℃'},
                {name:'湿度', value:57, unit:'%RH'},
            ]
        }
    },
    methods:{
        open(data){
            this.dialogVisible = true
            site_relicDetail({'relicCode':data.relicdetail.relicCode}).then(r=>{
                this.relicMsg = r.data
            })
            this.relicMsg['codeImg'] = null
            site_relicImg(data.relicid).then(r=>{
                setTimeout(()=>{
                    this.relicMsg['codeImg'] = r.data  
                },200)
            })
        },
        loadFiles(id){
            const url = `${this.imgUrl}?appendixId=${id}`
            const filename = '检测报告'
            const x = new XMLHttpRequest()
            x.open('GET', url, true)
            x.responseType = 'blob'
            x.onload = e => {
                const url = window.URL.createObjectURL(x.response)
                const a = document.createElement('a')
                a.href = url
                a.download = filename
                a.click()
            }
            x.send()
        }
    }
}
</script>
<style lang="scss" scoped>

.loTwo{
    width: 100%;

    .loTbox{
        width: 100%;
        margin: 0 auto;
        background: #ffffff;
        border: 1px solid gainsboro;
        padding: 40px 30px;
        box-sizing: border-box;
        .nameBox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            padding-left: 5px;
            border-left: 3px solid rgb(46, 107, 229);
            .nameImg{
                width: calc(100% - 100px);
                height: 1px;
                border-bottom: 2px dotted rgb(34, 126, 211);
                // background-color: skyblue;
            }
        }
        .basicBox{
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            .basicLeft{
                width: calc(100% - 320px);
                line-height: 30px;
                .BL-lineBox{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    .lineTips{
                        width: 120px;
                        color: #666666;
                        text-align: right;
                    }
                    .lineMsg{
                        width: calc(100% - 130px);
                    }
                }
                .BR-imgMsg{
                    width: 140px;
                    height: 140px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                
            }
            .basicRight{
                width: 300px;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                align-items: center;
                .BR-imgBox{
                    width: 300px;
                    height: 200px;
                    background: #a5a5a5;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                
            }
        }
        .storageBox{
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
            
        }
        .warningBox{
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
            .labelBox{
                display: inline-block;
                padding: 3px 5px;
                color: rgb(0, 115, 255);
                border: 1px solid rgb(0, 115, 255);
                margin:5px 0px;
            }
            .factsBox{
                width: 100%;
                display: flex;
                padding-left:20px;
                box-sizing: border-box;
                justify-content: space-between;
                .factItem{
                    width: calc(50% - 10px);
                    font-size:14px;
                }
            }
            .quesBox2{
                margin-top: 20px;
                margin-bottom: 30px;
                font-size:14px;
                width: 100%;
                padding: 20px;
                box-sizing: border-box;
                position: relative;
                border: 1px solid rgb(205, 205, 205); 
                .quesTitle2{
                    width: 80px;
                    height: 20px;
                    border: 1px solid rgb(205, 205, 205);
                    font-style: italic;
                    box-sizing: border-box;
                    line-height: 20px;
                    font-size: 12px;
                    position: absolute;
                    top:-10px;
                    left: 20px;
                    font-weight: bold;
                    text-align: center;
                    background-color: #FFFFFF;
                }
            }
            .disCss{
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

.quesBox{
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgb(205, 205, 205);
    .quesTitle{
        width: 80px;
        height: 20px;
        border: 1px solid rgb(205, 205, 205);
        font-style: italic;
        box-sizing: border-box;
        line-height: 20px;
        font-size: 12px;
        position: absolute;
        top:-10px;
        left: 20px;
        font-weight: bold;
        text-align: center;
        background-color: #FFFFFF;
    }
    .ques1{
        width: calc(33% - 10px);
        height: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ques1Img{
            width: 40%;
            height: 100%;
            background-color: rgb(159, 159, 159);
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .ques1Text{
            width: calc(60% - 20px);
            height: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .ques1File{
                text-decoration:underline;
                cursor: pointer;
            }
        }
    }
}


/deep/.el-breadcrumb__inner.is-link{
    color: white;
}
/deep/.el-breadcrumb__inner{
    color: #dddddd;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    color: #dddddd;
}
/deep/.el-carousel--horizontal{
    width: 100%;
    height: 100%;
}
.carouselImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>